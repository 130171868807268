import * as React from 'react';
import { createContext, ReactNode, useMemo, useState } from 'react';
import { createTheme, ThemeProvider, useMediaQuery } from '@mui/material';
import { getThemeOptions } from './theme';

interface ColorContextType {
  toggleColorMode: () => void;
}

const ColorModeContext = createContext<ColorContextType>({
  toggleColorMode: () => undefined
});

interface ColorModeProviderProps {
  children: ReactNode;
}

function ColorModeProvider({ children }: ColorModeProviderProps) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: light)');

  const [mode, setMode] = useState(prefersDarkMode ? 'dark' : 'light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      }
    }),
    []
  );

  const theme = useMemo(() => createTheme(getThemeOptions(mode)), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
}

function useColorContext() {
  const context = React.useContext(ColorModeContext);
  if (context === undefined) {
    throw new Error('toggleColorMode must be used within a ColorContextProvider');
  }
  return context;
}

export { ColorModeProvider, useColorContext };
