import { Autocomplete, Box, createFilterOptions, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import SelectableLabel from '../../../components/SelectableLabel';
import { LabelAlias } from '../../../types/label';

interface LabelOptionType {
  inputValue?: string;
  title: string;
}

const filter = createFilterOptions<LabelOptionType>();

interface ParameterAutoCompleteProps {
  availableLabels: LabelAlias[];
  selected?: string;
  onCreateParameter?: (name: string) => void;
  onLabelChange?: (label: LabelAlias) => void;
  onLabelCreate?: (title: string) => void;
  onLabelRemove?: () => void;
  onCancel?: () => void;
  openOnFocus?: boolean;
  label: string;
}

export const ParameterAutoComplete = ({
  selected,
  availableLabels,
  onLabelChange,
  onLabelCreate,
  onLabelRemove,
  onCancel,
  label
}: ParameterAutoCompleteProps) => {
  const [selectedLabel, setSelectedLabel] = useState<string>('');
  const [showSelect, setShowSelect] = useState<boolean>(false);

  useEffect(() => {
    if (selected) {
      const definition = availableLabels.find((l) => l.name === selected);
      if (definition) {
        setSelectedLabel(definition.name);
        setShowSelect(false);
      }
    }
  }, [availableLabels, selected]);

  const labelOptions: LabelOptionType[] = useMemo(() => {
    return availableLabels.map((label) => {
      return {
        title: label.name
      };
    });
  }, [availableLabels]);

  const handleOnCancel = () => {
    if (onCancel) {
      onCancel();
    }
    setShowSelect(false);
  };

  const handleOnLabelChange = (label: LabelAlias) => {
    if (onLabelChange) {
      onLabelChange(label);
    }
  };

  const handleOnLabelRemove = () => {
    if (onLabelRemove) {
      onLabelRemove();
    }
  };

  const handleOnChange = (update: LabelOptionType) => {
    const title = update ? update.title : '';

    if (selectedLabel === title) {
      handleOnCancel();
    } else {
      setSelectedLabel(title);
      const label = availableLabels.find((l) => l.name === title);

      if (label) {
        handleOnLabelChange(label);
      } else {
        handleOnLabelRemove();
      }
    }
    setShowSelect(false);
  };

  const handleOnCreate = (name: string) => {
    if (onLabelCreate) {
      onLabelCreate(name);
    }
  };

  const handleOnBlur = () => {
    setSelectedLabel('');
    handleOnCancel();
  };

  if (selectedLabel.length > 0 && !showSelect) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1
        }}
      >
        <SelectableLabel name={selectedLabel} onClick={() => setShowSelect(true)} />
      </Box>
    );
  }

  return (
    <Autocomplete
      freeSolo
      clearOnEscape
      openOnFocus={true}
      size="small"
      value={selectedLabel}
      options={labelOptions}
      renderInput={(params) => <TextField {...params} autoFocus={false} label={label} />}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          handleOnChange({
            title: newValue
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          handleOnCreate(newValue.inputValue);
        } else {
          handleOnChange(newValue);
        }
      }}
      onBlur={handleOnBlur}
      renderOption={(props, option) => <li {...props}>{option.title}</li>}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.title);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: `Create "${inputValue}"`
          });
        }

        return filtered;
      }}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Create "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
    />
  );
};
