import React from 'react';
import { Box, Typography } from '@mui/material';
import HelpText from './HelpText';
import ProgressCircle from './ProgressCircle';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';

export interface SectionPanelProps {
  title: string;
  subtitle?: string;
  help?: string;
  children: React.ReactNode;
  loading?: boolean;
  variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
}

export const SectionPanel = ({ title, variant, subtitle, help, children, loading }: SectionPanelProps) => {
  return (
    <Box sx={{ pt: '1rem', display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Typography variant={variant ? variant : 'h6'}>{title}</Typography>
      {subtitle && <Typography variant={'caption'}>{subtitle}</Typography>}
      {help && <HelpText text={help} />}
      {loading ? <ProgressCircle /> : children}
    </Box>
  );
};
