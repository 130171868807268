import { useLocation, useNavigate } from 'react-router-dom';
import { useMeQuery } from '../../state/api';
import React from 'react';
import { Box, List, ListItem, ListItemButton, ListItemText, styled } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import CompareIcon from '@mui/icons-material/Compare';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const StyledNav = styled(List)<{ component?: React.ElementType }>({
  '& .MuiListItemButton-root': {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 2,
    paddingBottom: 2
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 16
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20
  }
});

interface MenuListProps {
  onClick: () => void;
}

export const MenuList = ({ onClick }: MenuListProps) => {
  const location = useLocation();
  const { data: user } = useMeQuery();
  const navigate = useNavigate();

  const isSelected = (path: string): boolean => {
    return location.pathname.startsWith(path);
  };

  const handleOnClick = (path: string) => {
    onClick();
    navigate(path);
  };

  const menuButton = (path: string, title: string, Icon: React.ElementType) => {
    return (
      <ListItem disablePadding>
        <ListItemButton selected={isSelected(path)} onClick={() => handleOnClick(path)}>
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText primary={title} />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <StyledNav
        component="nav"
        sx={{
          pr: { xs: 2, sm: 0 }
        }}
      >
        {menuButton('/mirrors', 'Mirrors', CompareIcon)}
        {menuButton('/workflows', 'Workflows', AutoAwesomeIcon)}
        {/*{menuButton('/cups', 'CUP')}*/}
        {/*{menuButton('/documents', 'Documents', DescriptionIcon)}*/}

        {user.admin && menuButton('/admin', 'Admin', AdminPanelSettingsIcon)}
      </StyledNav>
    </Box>
  );
};
