import React from 'react';

interface Props {
  link: string;
  title: string;
}

export const Button = ({ link, title }: Props) => {
  return (
    <a
      className="mt-3 bg-opacity-65 bg-cyan-800 hover:bg-cyan-700 hover:bg-opacity-65 text-white font-bold py-2 rounded-xl"
      href={link}
    >
      {title}
    </a>
  );
};
