enum StandardLabel {
  EmploymentAgreement = 'EmploymentAgreement',
  ContractingParty = 'ContractingParty',
  ContractType = 'ContractType',
  ContractDuration = 'ContractDuration',
  TerminationDate = 'TerminationDate',
  TerminationNotice = 'TerminationNotice',
  StartDate = 'StartDate',
  Supplier = 'Supplier',
  AutoRenewal = 'AutoRenewal',
  GoverningLaw = 'GoverningLaw'
}

interface DocumentPageRequest {
  documentId: string;
  page: number;
  types: TextAnnotationType[];
}

enum TextAnnotationType {
  PAGE = 'PAGE',
  PARAGRAPH = 'PARAGRAPH',
  SENTENCE = 'SENTENCE',
  WORD = 'WORD',
  LINE = 'LINE',
  EXTRACTION = 'EXTRACTION'
}

interface BoundingBox {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface Baseline {
  slope: number;
  yOffset: number;
}

interface DocumentLine {
  page: number;
  line: number;
  box: BoundingBox;
  baseline: Baseline;
  descenders?: number;
  ascenders?: number;
  xSize?: string;
  words: DocumentWord[];
}

interface DocumentWord {
  page: number;
  line: number;
  number: number;
  box: BoundingBox;
  text?: string;
  fontSize?: number;
}

interface TextAnnotation {
  type: TextAnnotationType;
  box: BoundingBox;
  page: number;
  index: number;
  text?: string;
  xSize?: string;
  descenders?: number;
  ascenders?: number;
}

enum ExtractionValueType {
  DATE = 'DATE',
  TIMESTAMP = 'TIMESTAMP',
  DURATION = 'DURATION',
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  STRING_ARRAY = 'STRING_ARRAY'
}

interface ExtractionValue {
  type: ExtractionValueType;
  date?: string;
  timestamp?: string;
  duration?: string;
  string?: string;
  boolean?: boolean;
  stringArray: string[];
}

interface TextExtraction {
  textId: string;
  extractionId: string;
  createdAt: Date;
  labelId: string;
  labelName: string;
  labelTitle: string;
  start?: number;
  end?: number;
  original: string;
  value?: ExtractionValue;
  children: TextExtraction[];
}

interface LabelNew {
  name: string;
  type: string;
  customName?: string;
}

interface BooleanExtraction extends TextExtraction {
  boolean: boolean;
}

interface DateExtraction extends TextExtraction {
  date: Date;
}

interface StringExtraction extends TextExtraction {
  string: string;
}

interface DurationExtraction extends TextExtraction {
  duration: string;
}

interface ContractingPartyExtraction extends TextExtraction {
  contractingParty: string;
}

function isBooleanAnnotation(s: TextExtraction): s is BooleanExtraction {
  return s.value && s.value.type === ExtractionValueType.BOOLEAN;
}

function isDateAnnotation(s: TextExtraction): s is DateExtraction {
  return s.value && s.value.type === ExtractionValueType.DATE;
}

function isStringAnnotation(s: TextExtraction): s is StringExtraction {
  return s.value && s.value.type === ExtractionValueType.STRING;
}

function isDurationAnnotation(s: TextExtraction): s is DurationExtraction {
  return s.value && s.value.type === ExtractionValueType.DURATION;
}

export {
  BoundingBox,
  DocumentPageRequest,
  DocumentLine,
  DocumentWord,
  TextExtraction,
  BooleanExtraction,
  DateExtraction,
  StringExtraction,
  DurationExtraction,
  ContractingPartyExtraction,
  LabelNew,
  StandardLabel,
  TextAnnotation,
  TextAnnotationType,
  ExtractionValueType,
  isBooleanAnnotation,
  isDateAnnotation,
  isStringAnnotation,
  isDurationAnnotation
};
