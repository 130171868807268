import { Label, LabelAlias } from './label';

type WorkflowId = string;
type TaskId = string;
type ParameterId = string;

interface WorkflowDefinition {
  workflowId: string;
  name: string;
  tasks: TaskDefinition[];
  status: TaskStatus;
}

export interface TaskDefinition {
  taskId: string;
  name: string;
  status: TaskStatus;
  type: TaskType;
  input: TaskDefinitionInputParameter[];
  output: TaskParameter[];
  children: FlowGraphEdge[];
}

export interface TaskDefinitionInputParameter extends TaskParameter {
  mappedBy?: ParameterId;
  fulfilled: boolean;
}

export interface FlowGraphEdge {
  taskId: string;
  name?: string;
}

export enum TaskType {
  START = 'START',
  END = 'END',
  EXPORT = 'EXPORT',
  TASK = 'TASK',
  GROUP = 'GROUP',
  DECISION = 'DECISION',
  JOIN = 'JOIN',
  LOGIC = 'LOGIC',
  AGGREGATE = 'AGGREGATE',
  LABEL_PROVIDER = 'LABEL_PROVIDER',
  NOT_YET_DEFINED = 'NOT_YET_DEFINED'
}

interface TaskBasic {
  workflowId: string;
  taskId: string;
  name: string;
  description: string;
  status: TaskStatus;
}

interface Task {
  base: TaskBasic;
  action: AppAction;
  result?: AppActionResult;
}

interface AppAction {
  name: string;
  parameters: TaskParameter[];
}

interface AppActionResult {
  parameters: TaskParameter[];
  status: AppActionStatus;
  error?: string;
}

enum AppActionStatus {
  CREATED = 'CREATED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}

interface TaskParameterDefinition extends LabelAlias {
  parameterId: ParameterId;
}

interface TaskParameter extends Label, TaskParameterDefinition {
  value?: string;
}

enum TaskParameterType {
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING'
}

enum TaskStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  SKIPPED = 'SKIPPED',
  DONE = 'DONE',
  FAILED = 'FAILED'
}

interface TaskRequest {
  workflowId: WorkflowId;
  taskId: TaskId;
}

interface TaskNameUpdateRequest extends TaskRequest {
  name: string;
}

interface TaskCreateRequest {
  workflowId: WorkflowId;
  taskType: TaskType;
  selectedTaskId?: TaskId;
}

interface TaskParameterRequest extends TaskRequest {
  parameterId: ParameterId;
}

interface TaskJoinUpdate extends TaskRequest {
  keys: string[];
}

interface TaskJoinResponse {
  taskId: TaskId;
  keys: string[];
}

interface TaskLogicExpressionResponse {
  taskId: TaskId;
  expressions: TaskLogicExpression[];
}

interface TaskLogicExpressionRequest extends TaskRequest {
  mappedTo: ParameterId;
}

interface TaskLogicExpressionUpdate extends TaskLogicExpressionRequest {
  update: TaskLogicExpression;
}

interface TaskLogicExpression {
  logic: string;
  expression: string;
  mappedTo: ParameterId;
  mappedToName?: string;
}

enum LabelProviderType {
  OWNER = 'OWNER',
  USER = 'USER',
  CUP = 'CUP',
  EXTERNAL = 'EXTERNAL',
  API = 'API'
}

interface LabelProviderReference {
  providerId: string;
  providerType: LabelProviderType;
}

interface TaskInputParameterCreate extends TaskRequest {
  name: string;
  defaultValue?: string;
  mappedBy?: ParameterId;
}

interface TaskInputParametersAdd extends TaskRequest {
  inputs: TaskInputParameterAdd[];
}

interface TaskInputParameterAdd {
  labelId: string;
  name: string;
  defaultValue?: string;
  mappedBy?: ParameterId;
}

interface TaskOutputParameterCreate extends TaskRequest {
  labelId?: string;
  labelName?: string;
  defaultValue?: string;
}

enum ExportFileFormat {
  JSON = 'JSON',
  CSV = 'CSV'
}

interface ExportFormatColumn {
  index: number;
  mappedBy: TaskDefinitionInputParameter;
  name: string;
  suggestedName?: string;
}

interface ExportFormatTabular {
  fileFormat: ExportFileFormat;
  columns: ExportFormatColumn[];
}

interface TaskExportConfig {
  format: ExportFormatTabular;
  filenamePattern: string;
}

interface TaskExportConfigResponse extends TaskExportConfig {
  taskId: TaskId;
}

interface TaskExportConfigUpdate extends TaskRequest, TaskExportConfig {}

interface TaskExportColumnsAdd extends TaskRequest {
  columns: TaskExportColumnAdd[];
}

interface TaskExportColumnAdd {
  labelId: string;
  mappedBy: ParameterId;
}

interface TaskExportColumnCreate extends TaskRequest {
  name: string;
}

interface TaskAggregateConfigUpdate extends TaskRequest {
  config: TaskAggregateConfig;
}

interface TaskAggregateConfig {
  taskId: TaskId;
  keys: string[];
  functions: AggregateFunction[];
}

enum AggregateFunctionType {
  CONCAT = 'CONCAT',
  SUM = 'SUM'
}

interface AggregateFunctionOption {
  key: string;
  value: string;
}

interface AggregateFunction {
  labelId: string;
  type: AggregateFunctionType;
  options: AggregateFunctionOption[];
}

export {
  AppAction,
  AppActionResult,
  AppActionStatus,
  ExportFormatColumn,
  ExportFormatTabular,
  WorkflowDefinition,
  LabelProviderReference,
  LabelProviderType,
  ParameterId,
  Task,
  TaskAggregateConfig,
  AggregateFunctionOption,
  AggregateFunctionType,
  AggregateFunction,
  TaskAggregateConfigUpdate,
  TaskBasic,
  TaskCreateRequest,
  TaskExportConfig,
  TaskExportConfigResponse,
  TaskExportConfigUpdate,
  TaskExportColumnAdd,
  TaskExportColumnsAdd,
  TaskExportColumnCreate,
  TaskLogicExpression,
  TaskLogicExpressionResponse,
  TaskLogicExpressionRequest,
  TaskLogicExpressionUpdate,
  TaskJoinUpdate,
  TaskJoinResponse,
  TaskInputParameterCreate,
  TaskInputParametersAdd,
  TaskInputParameterAdd,
  TaskOutputParameterCreate,
  TaskNameUpdateRequest,
  TaskParameter,
  TaskParameterDefinition,
  TaskParameterRequest,
  TaskParameterType,
  TaskRequest,
  TaskStatus,
  TaskId,
  WorkflowId
};
