import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { usePostDevCommandMutation } from '../../state/api';
import { useNavigate } from 'react-router-dom';

export const Dev = () => {
  const [postDevCommand] = usePostDevCommandMutation();
  const navigate = useNavigate();

  const handleCreateMirror = async (event) => {
    event.preventDefault();
    try {
      await postDevCommand()
        .unwrap()
        .then(() => {
          navigate(`/mirrors`);
        });
    } catch (error) {
      console.error('creating new CUP failed:', error);
    }
  };

  return (
    <form>
      <Box sx={{ minWidth: 500, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h6" component="div">
          Developer View
        </Typography>
        <Typography variant="body2" component="div"></Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
          <Button variant="contained" type="button" onClick={handleCreateMirror}>
            Create Mirror
          </Button>
        </Box>
      </Box>
    </form>
  );
};
