import { useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import React from 'react';
import { User } from '../state/model';

interface UserAvatarProps {
  user: User;
  small?: boolean;
}

const UserAvatar = ({ user, small = false }: UserAvatarProps) => {
  const theme = useTheme();
  const size = small ? theme.spacing(3.2) : theme.spacing(3.2);

  return (
    <Avatar
      variant="square"
      sx={{
        width: size,
        height: size,
        padding: 1,
        borderRadius: theme.spacing(1)
      }}
    >
      {user.username.charAt(0)}
    </Avatar>
  );
};

export default UserAvatar;
