import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MapComponent from './map';
import DocumentList from './DocumentList';
import { useGetDocumentsQuery, useLazyGetExtractionsByDocumentIdQuery } from '../../state/api';
import { convert } from './map/model';
import SectionHeader from '../../components/SectionHeader';
import { TextExtraction } from '../../types/annotation';
import UploadArea from './UploadArea';
import { DocumentDetails } from '../../types/document';
import { Alert } from '@mui/material';
import SectionBody from '../../components/SectionBody';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ pt: 1, display: value === index ? 'block' : 'none' }}>{children}</Box>
    </div>
  );
}

export default function Documents() {
  const [value, setValue] = useState(0);
  const [search, setSearch] = useState('');
  const [documents, setDocuments] = useState([]);
  const [mapPoints, setMapPoints] = useState([]);
  const [extractions, setExtractions] = useState<Map<string, TextExtraction[]>>(new Map());
  const [, setProcessId] = React.useState('');

  const { data = [], isLoading } = useGetDocumentsQuery();
  const [triggerGetExtractionsByDocumentId] = useLazyGetExtractionsByDocumentIdQuery();

  const handleChange = (event: React.ChangeEvent<object>, newValue: number) => {
    setValue(newValue);
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const filterDocuments = (details: DocumentDetails[], filter: string): DocumentDetails[] => {
    if (filter.length > 0) {
      return details.filter((t) => t.name.includes(filter));
    } else {
      return details;
    }
  };

  React.useEffect(() => {
    if (data) {
      const filteredData = filterDocuments(data, search);
      filteredData.forEach((doc) => {
        triggerGetExtractionsByDocumentId(doc.id).then((result) => {
          if (result.data) {
            setExtractions((prevExtractions) => {
              const updatedExtractions = new Map(prevExtractions);
              updatedExtractions.set(doc.id, result.data);
              return updatedExtractions;
            });
          }
        });
      });
      setDocuments(filteredData);
      const partyPoints = filteredData.map((doc) => {
        return convert(doc)
          .filter((partyMarker) => partyMarker.location)
          .map((partyMarker) => {
            return {
              id: partyMarker.extractionId,
              lat: partyMarker.location.lat,
              lng: partyMarker.location.lng,
              data: partyMarker
            };
          });
      });
      setMapPoints(partyPoints.flat());
    } else {
      setMapPoints([]);
    }
  }, [search, data]);

  return (
    <>
      <SectionHeader title="Documents">
        <TextField
          id="outlined-basic"
          label="Search"
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          size="small"
          sx={{
            width: '100%',
            maxWidth: '30rem'
          }}
        />
        <UploadArea onProcessStarted={setProcessId} />
      </SectionHeader>
      <SectionBody>
        {documents.length === 0 && <Alert severity="info">No documents available</Alert>}
        {documents.length > 0 && (
          <>
            <Box>
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Documents" />
                <Tab label="Map View" />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <DocumentList documents={documents} extractions={extractions} isLoading={isLoading} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <MapComponent mapPoints={mapPoints} />
            </TabPanel>
          </>
        )}
      </SectionBody>
    </>
  );
}
