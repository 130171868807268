import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { BoundingBox, DocumentLine, TextAnnotation, TextAnnotationType } from '../../types/annotation';
import { useTheme } from '@mui/material';

interface DocumentAnnotationProps {
  width: number;
  height: number;
  lines: DocumentLine[];
  annotations: TextAnnotation[];
  renderText?: boolean;
  renderCursor?: boolean;
}

interface AnnotationBox extends TextAnnotation {
  fill: string;
}

interface MouseCursor {
  x: number;
  y: number;
}

const DocumentOverlay = ({
  width,
  height,
  lines,
  annotations,
  renderText = false,
  renderCursor = false
}: DocumentAnnotationProps) => {
  const theme = useTheme();
  // const [counter, setCounter] = useState(0);
  const [cursor, setCursor] = useState<MouseCursor>({ x: 0, y: 0 });
  const [boxes, setBoxes] = useState<AnnotationBox[]>([]);

  const canvasRef = useRef<HTMLCanvasElement>();

  useEffect(() => {
    setBoxes(
      annotations.map((a) => {
        return { ...a, fill: `${theme.palette.primary.main}20` };
      })
    );
  }, [annotations]);

  const handleMouseMove: MouseEventHandler = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();

    const scaleX = width / rect.width;
    const scaleY = height / rect.height;

    const x = (event.clientX - rect.left) * scaleX;
    const y = (event.clientY - rect.top) * scaleY;

    setCursor({
      x,
      y
    });

    setBoxes((prev) =>
      prev.map((a) => {
        if (isHovering(cursor, a.box)) {
          return { ...a, fill: `${theme.palette.primary.main}40` };
        }

        return { ...a, fill: `${theme.palette.primary.main}30` };
      })
    );
  };
  const handleOnClick: MouseEventHandler = () => {
    const annotation: AnnotationBox = {
      type: TextAnnotationType.EXTRACTION,
      box: { x: cursor.x, y: cursor.y, width: 80, height: 20 },
      page: 1,
      index: 1,
      fill: '#888'
    };
    setBoxes((prev) => [...prev, annotation]);
  };

  const isHovering = (cursor: MouseCursor, box: BoundingBox): boolean => {
    const x = cursor.x;
    const y = cursor.y;

    return box.x <= x && box.x + box.width >= x && box.y <= y && box.y + box.height >= y;
  };

  const drawLines = (ctx: CanvasRenderingContext2D) => {
    lines.forEach((line) => {
      // ctx.fillStyle = hoverStyle(cursor, line.box);
      // ctx.beginPath();
      // ctx.fillRect(line.box.x, line.box.y, line.box.width, line.box.height);
      // ctx.fill();

      line.words.forEach((word) => {
        if (isHovering(cursor, word.box)) {
          ctx.fillStyle = `${theme.palette.primary.main}65`;
          ctx.beginPath();
          ctx.fillRect(word.box.x, word.box.y, word.box.width, word.box.height);
          ctx.fill();
        }

        if (renderText) {
          ctx.font = `${word.fontSize}px Liberation Mono`;
          ctx.textBaseline = 'alphabetic';
          ctx.fillStyle = theme.palette.text.primary;
          ctx.fillText(word.text, word.box.x, line.box.y + line.box.height + line.baseline.yOffset, word.box.width);
          ctx.fill();
        }
      });
    });
  };

  const drawAnnotation = (ctx: CanvasRenderingContext2D) => {
    boxes.forEach((a) => {
      ctx.fillStyle = a.fill;
      ctx.beginPath();
      ctx.fillRect(a.box.x, a.box.y, a.box.width, a.box.height);
      ctx.fill();

      if (a.text) {
        ctx.font = `${a.xSize}px Serif`;
        ctx.textBaseline = 'top';
        ctx.fillStyle = theme.palette.text.primary;
        ctx.fillText(a.text, a.box.x, a.box.y, a.box.width);
        ctx.fill();

        ctx.fillStyle = theme.palette.secondary.main;
        ctx.fillRect(a.box.x, a.box.y, a.box.width, a.ascenders);
        ctx.fillRect(a.box.x, a.box.y + a.box.height - a.descenders, a.box.width, a.descenders);
        ctx.fill();
      }
    });
  };

  const drawCursor = (ctx: CanvasRenderingContext2D) => {
    if (renderCursor && isHovering(cursor, { x: 50, y: 50, width: width - 100, height: height - 100 })) {
      ctx.fillStyle = theme.palette.primary.dark;
      ctx.beginPath();
      ctx.fillRect(cursor.x - 10, cursor.y - 10, 20, 20);
      ctx.fill();
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    context.clearRect(0, 0, canvas.width, canvas.height);
    // context.fillStyle = '#ffffff60';
    // context.fillRect(0, 0, canvas.width, canvas.height);
    // context.fill();

    drawLines(context);
    drawAnnotation(context);
    drawCursor(context);
  }, [boxes, cursor]);

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      onClick={handleOnClick}
      onMouseMove={handleMouseMove}
      style={{ gridArea: '1 / 1', width: '100%', cursor: 'text' }}
    />
  );
};
export default DocumentOverlay;
