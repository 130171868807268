export const getThemeOptions = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light' && {
      primary: {
        main: '#528395'
      },
      secondary: {
        main: '#96897b'
      },
      background: {
        // default: '#f1f0f0', // bluish
        default: '#f8f8f8', // reddish
        // default: '#fdfdfd',
        paper: '#fdfdfd'
      },
      info: {
        main: '#0a7aa6'
      },
      success: {
        main: '#bcd0ad',
        light: '#679636'
      }
    }),
    ...(mode === 'dark' && {
      primary: {
        main: '#528395'
      },
      secondary: {
        main: '#96897b'
      },
      background: {
        default: '#1e1f22',
        paper: '#1e1f22'
        // paper: '#2b2d30'
      },
      info: {
        main: '#0a7aa6'
      },
      success: {
        main: '#bcd0ad',
        light: '#679636'
      },
      text: {
        primary: '#BCBEC4'
      }
      //       Text Background: 1E1F22
      //     Text Foreground: BCBEC4
      //
      // Scrollbar Thumb: A6A6A647
      // Scrollbar Thumb Scrolling: A6A6A659
      //
      // Orange Keyword: CF8E6D
    })
  },
  shape: {
    borderRadius: 16
  },
  typography: {
    fontSize: 13,
    button: {
      fontWeight: 400,
      lineHeight: 1.45
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: 'small' as const
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small' as const
      }
    }
  }
});
