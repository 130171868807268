import React from 'react';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable
} from '@dnd-kit/sortable';
import { Box } from '@mui/material';
import { ExportFormatColumn, TaskDefinitionInputParameter, TaskExportConfig } from '../../../types/task';
import SelectableLabel from '../../../components/SelectableLabel';

interface SortableTableCellProps {
  name: string;
  mappedBy: TaskDefinitionInputParameter;
  onDelete: () => void;
  onSelectColumn: () => void;
  selected: boolean;
}

const SortableTableCell = ({ name, mappedBy, onDelete, onSelectColumn, selected }: SortableTableCellProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: mappedBy.parameterId });

  const transformOnlyTranslate = transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : '';

  const style = {
    transform: transformOnlyTranslate,
    transition,
    opacity: 1
  };

  return (
    <Box ref={setNodeRef} style={style}>
      <SelectableLabel
        attributes={attributes}
        listeners={listeners}
        name={name}
        onClick={() => onSelectColumn()}
        selected={selected}
        onDelete={onDelete}
        fulfilled={mappedBy.mappedBy !== undefined}
      />
    </Box>
  );
};

export interface MirrorExportTaskColumnEditorProps {
  workflowId: string;
  taskId: string;
  columns: ExportFormatColumn[];
  exportConfig: TaskExportConfig;
  isColumnSelected: (config: ExportFormatColumn) => boolean;
  onSaveConfig: (updated: ExportFormatColumn[]) => void;
  onColumnDelete: (config: ExportFormatColumn) => void;
  onColumnClick: (config: ExportFormatColumn) => void;
}

export const MirrorExportTaskColumnEditor = ({
  columns,
  onColumnClick,
  onColumnDelete,
  isColumnSelected,
  onSaveConfig
}: MirrorExportTaskColumnEditorProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = columns.findIndex((column) => column.mappedBy.parameterId === active.id);
      const newIndex = columns.findIndex((column) => column.mappedBy.parameterId === over.id);

      const newColumns = columns.map((item, index) => {
        if (index === newIndex) {
          return { ...item, index: columns[oldIndex].index };
        } else if (index === oldIndex) {
          return { ...item, index: columns[newIndex].index };
        }
        return item;
      });
      onSaveConfig(newColumns);
    }
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext
        items={columns.map((column) => column.mappedBy.parameterId)}
        strategy={horizontalListSortingStrategy}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            flexWrap: 'wrap'
          }}
        >
          {columns.map((column) => (
            <SortableTableCell
              key={column.mappedBy.parameterId}
              name={column.name}
              mappedBy={column.mappedBy}
              onDelete={() => onColumnDelete(column)}
              selected={isColumnSelected(column)}
              onSelectColumn={() => onColumnClick(column)}
            />
          ))}
        </Box>
      </SortableContext>
    </DndContext>
  );
};
