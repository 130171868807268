import { Autocomplete, Box, Chip, createFilterOptions, TableCell, TextField } from '@mui/material';
import LinkOnIcon from '@mui/icons-material/Link';
import React, { ReactNode, useMemo, useState } from 'react';
import { CupTabularDataColumn } from '../../../types/cup';
import { LabelParameters } from '../../../types/label';

interface StyledTableCellProps {
  children: ReactNode;
}

const StyledTableHeaderCell = ({ children }: StyledTableCellProps) => {
  return <TableCell sx={{ whiteSpace: 'nowrap', minWidth: '8rem' }}>{children}</TableCell>;
};

interface LabelOptionType {
  inputValue?: string;
  title: string;
}

const filter = createFilterOptions<LabelOptionType>();

interface CupTabularDataHeaderProps {
  column: CupTabularDataColumn;
  onColumnClick: (column: CupTabularDataColumn) => void;
  selected?: boolean;
  onLabelChange: (label: LabelParameters) => void;
  onLabelCreate: (title: string) => void;
  onLabelRemove: () => void;
  onCancel: () => void;
  availableLabels: LabelParameters[];
}

export const CupTabularDataHeader = ({
  column,
  selected = false,
  onColumnClick,
  availableLabels,
  onLabelChange,
  onLabelCreate,
  onLabelRemove,
  onCancel
}: CupTabularDataHeaderProps) => {
  const [selectedLabel, setSelectedLabel] = useState('');

  const labelOptions: LabelOptionType[] = useMemo(() => {
    return availableLabels.map((label) => {
      return {
        title: label.name
      };
    });
  }, [availableLabels]);

  const handleOnChange = (update: LabelOptionType) => {
    const title = update ? update.title : '';

    if (selectedLabel === title) {
      onCancel();
    } else {
      setSelectedLabel(title);
      const label = availableLabels.find((l) => l.name === title);

      if (label) {
        onLabelChange(label);
      } else {
        onLabelRemove();
      }
    }
  };

  const handleOnCreate = (name: string) => {
    onLabelCreate(name);
  };

  const handleOnBlur = () => {
    setSelectedLabel('');
    onCancel();
  };

  if (selected) {
    return (
      <StyledTableHeaderCell>
        <Autocomplete
          freeSolo
          clearOnEscape
          openOnFocus
          size="small"
          componentsProps={{ popper: { style: { width: 'fit-content' } } }}
          value={selectedLabel}
          options={labelOptions}
          renderInput={(params) => <TextField {...params} autoFocus label="Label" />}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              handleOnChange({
                title: newValue
              });
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              handleOnCreate(newValue.inputValue);
            } else {
              handleOnChange(newValue);
            }
          }}
          onBlur={handleOnBlur}
          renderOption={(props, option) => <li {...props}>{option.title}</li>}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.title);
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                title: `Add "${inputValue}"`
              });
            }

            return filtered;
          }}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.title;
          }}
        />
        <Box>{column.originalName}</Box>
      </StyledTableHeaderCell>
    );
  }

  return (
    <StyledTableHeaderCell>
      {column.label ? (
        <Chip
          size="small"
          color="success"
          label={column.label.name}
          onClick={() => onColumnClick(column)}
          onDelete={onLabelRemove}
          sx={{ mt: '1rem' }}
        />
      ) : (
        <Chip
          size="small"
          color="primary"
          label={'select label'}
          variant="outlined"
          onClick={() => onColumnClick(column)}
          icon={<LinkOnIcon />}
          sx={{ mt: '1rem' }}
        />
      )}
      <Box>{column.originalName}</Box>
    </StyledTableHeaderCell>
  );
};
