import React, { useEffect, useState } from 'react';
import { CircularProgress, Divider, Typography } from '@mui/material';
import { useGetDocumentByIdQuery, useGetExtractionsByDocumentIdQuery } from '../../state/api';
import { useParams } from 'react-router-dom';
import {
  isBooleanAnnotation,
  isDateAnnotation,
  isDurationAnnotation,
  isStringAnnotation,
  StandardLabel,
  TextAnnotationType,
  TextExtraction
} from '../../types/annotation';
import DocumentPageSection from './DocumentPageSection';
import SectionHeader from '../../components/SectionHeader';
import SectionBody from '../../components/SectionBody';

interface AnnotationProps {
  annotation: TextExtraction;
}

const Document = () => {
  const { documentId } = useParams();
  const { data: document, isLoading: isDocumentLoading } = useGetDocumentByIdQuery(documentId);
  const { data: annotations, isLoading: isAnnotationsLoading } = useGetExtractionsByDocumentIdQuery(documentId);
  const [selectedTypes = [TextAnnotationType.EXTRACTION] /*,setSelectedTypes */] = useState<TextAnnotationType[]>([
    TextAnnotationType.EXTRACTION
  ]);

  useEffect(() => {
    if (annotations) {
      /* annotations.forEach((annotation) => {
        if (annotation.contractingParty) {
          triggerGetContractingPartyQuery({ documentId: documentId, partyId: annotation.contractingParty }).then(
            (result) => {
              if (result.data) {
                setParties((prevParties) => {
                  const updatedParties = new Map(prevParties);
                  updatedParties.set(annotation.contractingParty, result.data);
                  return updatedParties;
                });
              }
            }
          );
        }
      });*/
    }
  }, [annotations]);

  if (isDocumentLoading || isAnnotationsLoading) {
    return <CircularProgress />;
  }

  // const toggleType = (type: TextAnnotationType) => {
  //   if (selectedTypes.includes(type)) {
  //     setSelectedTypes(selectedTypes.filter((t) => t !== type));
  //   } else {
  //     setSelectedTypes([...selectedTypes, type]);
  //   }
  // };

  const Annotation = ({ annotation }: AnnotationProps) => {
    let value = '';
    if (isBooleanAnnotation(annotation)) {
      value = '' + annotation.value.boolean;
    } else if (annotation.labelName == StandardLabel.ContractingParty) {
      console.log(annotation);
      const party = annotation.children.find((child) => child.labelName == 'PartyName');
      if (party) {
        value = party.value.string;
      }
    } else if (isStringAnnotation(annotation)) {
      value = annotation.value.string;
    } else if (isDateAnnotation(annotation)) {
      value = new Date(annotation.value.date).toISOString().substring(0, 10);
    } else if (isDurationAnnotation(annotation)) {
      value = annotation.value.duration;
    }

    return (
      <Typography variant={'body2'} sx={{ display: 'block' }}>
        {annotation.labelTitle}: {value && value.length == 0 ? annotation.original : value}
      </Typography>
    );
  };

  return (
    <>
      <SectionHeader title={document.details.name} />
      <SectionBody centered overflow="auto">
        {annotations
          .slice()
          .sort((a, b) => a.labelTitle.localeCompare(b.labelTitle))
          .map((annotation) => (
            <Annotation key={annotation.extractionId} annotation={annotation} />
          ))}
        {/*{Object.keys(TextAnnotationType).map((type) => (*/}
        {/*  <FormGroup key={type}>*/}
        {/*    <FormControlLabel*/}
        {/*      control={<Checkbox onChange={() => toggleType(TextAnnotationType[type])} />}*/}
        {/*      label={type.toString()}*/}
        {/*    />*/}
        {/*  </FormGroup>*/}
        {/*))}*/}
        {document.pages.map((page, index) => (
          <>
            {index > 0 && <Divider />}
            <DocumentPageSection key={page.page} documentId={documentId} page={page} showAnnotations={selectedTypes} />
          </>
        ))}
      </SectionBody>
    </>
  );
};

export default Document;
