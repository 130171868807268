import React from 'react';
import logo from '../../assets/digital-mirror-logo-blue_128.png';
import wavy from '../../assets/bg-wavy.mp4';
import thumbnail from '../../assets/bg-wavy-thumbnail.jpeg';
import '../../output/index-tw.css';
import { Typography } from '../../components/landingpage/Typography';
import { Button } from '../../components/landingpage/Button';
import { useNavigate } from 'react-router-dom';

const LandingPage: React.FC = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <main className="h-full flex flex-col">
      <div className="h-full w-full object-cover object-center absolute bg-gradient-to-b from-gray-700 to-black">
        <video
          playsInline
          preload="auto"
          muted
          loop
          autoPlay
          crossOrigin="anonymous"
          className="h-full w-full object-cover object-center absolute"
          poster={thumbnail}
        >
          <source src={wavy} type="video/mp4" />
        </video>

        <div className="opacity-80 h-full w-full object-cover object-center absolute bg-gradient-to-b from-gray-700 to-black" />
      </div>

      <div className="flex flex-col justify-center items-center h-full gap-4">
        <div className="max-h-24 w-full z-40 flex flex-row justify-center">
          <img src={logo} alt="Digital Mirror Logo" className="max-w-xs sm:max-w-md z-40" />
        </div>

        <div className="font-inter w-full max-w-3xl flex flex-col gap-5 md:gap-20 overflow-auto p-5 z-40">
          <section className="w-full relative flex justify-center items-center flex-col p-15 gap-10">
            <article className="w-full text-center text-white">
              <Typography variant="h1" className="text-white w-full mb-7">
                We are in stealth mode and launching soon
                <div className="inline-block relative text-cyan-400">
                  .
                  <span className="animate-ping absolute bottom-[0.465rem] sm:bottom-[0.44rem] left-0 ml-[0.155rem] h-1 sm:h-2 w-1 sm:w-2 rounded-full bg-cyan-400 opacity-75"></span>
                </div>
              </Typography>
              <Typography variant="body">
                A multi-model AI-powered No-code Content Understanding Platform (CUP) to Empower the knowledge worker to
                automate tedious portions of their work and dynamically engage with content via questions, answers, and
                actions.
              </Typography>
            </article>

            <article className="w-full max-w-xs text-center flex flex-col">
              <Typography variant="h3" className="text-cyan-500">
                Want to know more?
              </Typography>
              <Typography variant="small" className="text-white">
                Subscribe to get the latest info
              </Typography>
              <Button link="mailto:info@digital-mirror.ai" title="Subscribe" />
            </article>

            <article className="w-full max-w-xs text-center flex flex-col">
              <Typography variant="h3" className="text-cyan-500">
                Ready to work with us?
              </Typography>
              <Typography variant="small" className="text-white">
                We are looking for the brightest talent to help us launch our software. Developers, sales, and customer
                success professionals
              </Typography>
              <Button link="mailto:info@digital-mirror.ai" title="Contact" />
            </article>

            <article className="w-full max-w-xs text-center flex flex-col">
              <button
                onClick={handleLoginClick}
                className="bg-opacity-65 bg-cyan-800 hover:bg-cyan-700 hover:bg-opacity-65 text-white font-bold py-2 rounded-xl"
              >
                Sign In
              </button>
            </article>
          </section>
        </div>
      </div>
    </main>
  );
};

export default LandingPage;
