import React from 'react';
import {
  Collapse,
  Divider,
  Link,
  List,
  ListItem,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { TaskAndProgress, WorkflowInstance } from '../../types/workflow';
import { useGetTaskInstancesQuery } from '../../state/api';
import { SystemLabels } from '../../types/label';
import { config } from '../../config';
import { TaskType } from '../../types/task';
import SectionTable from '../../components/SectionTable';

interface WorkflowProgressProps {
  workflow: WorkflowInstance;
  task: TaskAndProgress;
  open: boolean;
}

export const TaskResultView = ({ workflow, task, open }: WorkflowProgressProps) => {
  const { data: instances = [] } = useGetTaskInstancesQuery(
    {
      workflowInstanceId: workflow.workflowInstanceId,
      taskId: task.taskId
    },
    { skip: !open }
  );

  if (instances.length == 0) {
    return (
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Typography variant="body2">No output found</Typography>
      </Collapse>
    );
  }

  const hasDataOutput = task.type !== TaskType.EXPORT;

  const dataTable = () => {
    const columnParameters = task.output.map((o) => o.parameterId);
    const columns = task.output.map((o) => <TableCell key={o.parameterId}>{o.description.title}</TableCell>);

    const rows =
      columnParameters.length === 0
        ? []
        : instances.map((instance) => {
            const mappedByParameter = new Map(instance.output.map((o) => [o.definition.parameterId, o.value]));

            const dataColumns = columnParameters.map((c) => (
              <TableCell key={`${instance.taskInstanceId}-${c}`}>{mappedByParameter.get(c)}</TableCell>
            ));

            return <TableRow key={instance.taskInstanceId}>{dataColumns}</TableRow>;
          });

    return (
      <SectionTable>
        <TableHead>
          <TableRow>{columns}</TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </SectionTable>
    );
  };

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      {hasDataOutput ? (
        dataTable()
      ) : (
        <List>
          {instances.map((instance, index) => (
            <>
              {index > 0 && <Divider sx={{ my: 2 }} />}
              {instance.output &&
                instance.output.map((o) => (
                  <ListItem
                    key={instance.taskInstanceId}
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                  >
                    <Typography key={o.definition.parameterId} variant="body1" sx={{ mb: 0.5 }}>
                      {o.definition.name}:{' '}
                      {o.definition.labelId === SystemLabels.DocumentId ? (
                        <Link href={`${config.apiUrl}/api/documents/${o.value}/download-export`}>{o.value}</Link>
                      ) : (
                        o.value
                      )}
                    </Typography>
                  </ListItem>
                ))}
            </>
          ))}
        </List>
      )}
    </Collapse>
  );
};
