import React, { useMemo } from 'react';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { DocumentBasic, DocumentSet } from '../../types/document';
import { useNavigate } from 'react-router-dom';
import SectionTable from '../../components/SectionTable';

interface FilenameTableProps {
  documentSet?: DocumentSet;
}

export const DocumentSetView = ({ documentSet }: FilenameTableProps) => {
  const navigate = useNavigate();

  const fileStats: DocumentBasic[] = useMemo(() => {
    if (documentSet) {
      return documentSet.documents;
    }
    return [];
  }, [documentSet]);

  if (fileStats.length === 0) {
    return <></>;
  }

  return (
    <SectionTable>
      <TableHead>
        <TableRow>
          <TableCell>Filename</TableCell>
          <TableCell>Language</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {fileStats.map((fileStat, index) => (
          <TableRow key={index} onClick={() => navigate(`/documents/${fileStat.documentId}`)}>
            <TableCell>{fileStat.name}</TableCell>
            <TableCell>{fileStat.language}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </SectionTable>
  );
};
