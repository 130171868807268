import React, { useEffect, useState } from 'react';
import { Cup } from '../../../types/cup';
import UploadArea from '../../documents/UploadArea';
import { UploadReferenceType } from '../../../types/upload';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import { CupTabularDataTable } from './CupTabularDataTable';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

export interface CupTabularDataEditorProps {
  cup: Cup;
}

export const CupTabularDataEditor = ({ cup }: CupTabularDataEditorProps) => {
  const [cupState, setCupState] = useState<Cup>(cup);
  const [editExampleFileMode, setEditExampleFileMode] = useState(false);
  const [uploadingExample, setUploadingExample] = useState(false);
  const handleEditClick = () => {
    setEditExampleFileMode(true);
  };

  const handleFileUpload = () => {
    setCupState((prevState) => ({
      ...prevState,
      exampleDocuments: [],
      tabularData: null
    }));
  };

  useEffect(() => {
    setCupState(cup);
    setEditExampleFileMode(false);
    setUploadingExample(false);
  }, [cup]);

  const handleCancelFileUpload = () => {
    setEditExampleFileMode(false);
    setUploadingExample(false);
  };

  useEffect(() => {
    setCupState(cup);
    setEditExampleFileMode(false);
  }, [cup]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flex: 1,
        position: 'relative'
      }}
    >
      <Box sx={{ p: 1 }}>
        {(cupState.exampleDocuments && cupState.exampleDocuments.length == 0) ||
          (editExampleFileMode && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', mb: 3 }}>
              <span>
                {editExampleFileMode ? 'Upload a new example CSV or XLS file' : 'Upload an example CSV or XLS file'}
              </span>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 1 }}>
                <UploadArea
                  reference={{ referenceId: cupState.id, referenceType: UploadReferenceType.CUP }}
                  onProcessStarted={handleFileUpload}
                  onDropped={() => setUploadingExample(true)}
                />
                {!uploadingExample && (
                  <Button
                    variant="outlined"
                    component={'label'}
                    color="primary"
                    size="medium"
                    onClick={handleCancelFileUpload}
                  >
                    Cancel
                  </Button>
                )}
              </Box>
            </Box>
          ))}
      </Box>
      {cupState.exampleDocuments && cupState.exampleDocuments.length > 0 && !editExampleFileMode && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            flex: 1,
            width: '100%',
            height: '100%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 0,
              p: 0
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="subtitle2">Example Document</Typography>
              <Typography variant="body2">{cupState.exampleDocuments[0].name}</Typography>
              <Divider sx={{ height: 18, m: 0.0 }} orientation="vertical" />
              <IconButton color="default" size="small" onClick={handleEditClick}>
                <EditIcon />
              </IconButton>
            </Box>
          </Box>
          <CupTabularDataTable cup={cupState} />
        </Box>
      )}
    </Box>
  );
};
