import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Config, ContractingParty, ContractingPartyQuery, SignupRequest, UpdateUserRequest, User } from './model';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { config } from '../config';
import { ProcessDetails } from '../types/process';
import { Label, Label as Label_, LabelCreate, LabelGet } from '../types/label';
import { DocumentLine, DocumentPageRequest, TextAnnotation, TextExtraction } from '../types/annotation';
import { DocumentDetails, DocumentFull, DocumentSet, DocumentsQuery as DocumentQuery } from '../types/document';
import { Form } from '../types/form';
import {
  ChatCreatedResponse,
  ChatCreateMessageRequest,
  ChatCreateTextRequest,
  ChatDeleteMessageRequest,
  ChatItem,
  ChatMessage,
  ChatMessageCreatedResponse,
  ChatMessageUpdatedResponse,
  ChatUpdateMessageRequest,
  ChatUploadResponse
} from '../types/chat';
import { CreateUploadRequest } from '../types/upload';
import {
  LabelProviderReference,
  Task,
  TaskAggregateConfig,
  TaskAggregateConfigUpdate,
  TaskCreateRequest,
  TaskExportColumnCreate,
  TaskExportColumnsAdd,
  TaskExportConfigResponse,
  TaskExportConfigUpdate,
  TaskInputParameterCreate,
  TaskInputParametersAdd,
  TaskJoinResponse,
  TaskJoinUpdate,
  TaskLogicExpressionRequest,
  TaskLogicExpressionResponse,
  TaskLogicExpressionUpdate,
  TaskNameUpdateRequest,
  TaskOutputParameterCreate,
  TaskParameterRequest,
  TaskRequest,
  WorkflowDefinition
} from '../types/task';
import {
  Cup,
  CupCreate,
  CupCreateComplete,
  CupCreateExtraction,
  CupDeleteFilter,
  CupFilterConfig,
  CupGetTabularExampleData,
  CupSaveFilter,
  CupSaveLabels,
  CupTabularExampleData,
  CupTest,
  CupUpdateTabularDataColumn
} from '../types/cup';
import {
  Mirror,
  MirrorBasic,
  MirrorCreate,
  MirrorCupProviderCreate,
  MirrorLabelProvider,
  MirrorLabelProviderUpdate,
  MirrorNameUpdate,
  MirrorState,
  MirrorUserProviderCreate
} from '../types/mirror';
import { DemoState, DemoStateUpdate } from '../types/demo';
import {
  OutputParameterDefinition,
  TaskInstance,
  TaskInstanceIdentity,
  WorkflowBasic,
  WorkflowCreateRequest,
  WorkflowInstance
} from '../types/workflow';
import { AuthState, logout } from './authSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: config.apiUrl,
  credentials: 'include'
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    if ((api.getState() as AuthState).isLoggedIn) {
      api.dispatch(logout());
    }
  }
  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Chat',
    'Config',
    'User',
    'Document',
    'DocumentAnnotations',
    'DocumentPageAnnotations',
    'DocumentPageStructure',
    'DocumentSet',
    'Label',
    'Processes',
    'TaskBasic',
    'Task',
    'FlowGraph',
    'Cup',
    'CupTabularExampleData',
    'CupLabel',
    'Mirror',
    'MirrorBasic',
    'MirrorState',
    'TaskLogicExpression',
    'TaskAggregateConfig',
    'TaskExportConfig',
    'TaskInstance',
    'TaskJoin',
    'LabelProviderReference',
    'DemoState',
    'WorkflowBasic',
    'WorkflowDefinition',
    'WorkflowInstance',
    'UsersToActivate'
  ],
  reducerPath: 'hydraApi',
  endpoints: (build) => ({
    me: build.query<User, void>({
      query: () => `/api/me`,
      providesTags: ['User']
    }),
    logoutUser: build.mutation<void, void>({
      query: () => ({
        url: '/api/logout',
        method: 'POST'
      }),
      invalidatesTags: ['User']
    }),
    getConfig: build.query<Config, void>({
      query: () => ({
        url: '/api/config',
        providesTags: ['Config']
      })
    }),
    getContractingParty: build.query<ContractingParty, ContractingPartyQuery>({
      query: (query) => ({
        url: `/api/parties/${query.extractionId}`,
        providesTags: []
      })
    }),
    updateUser: build.mutation<void, UpdateUserRequest>({
      query: (request) => ({
        url: 'api/users',
        method: 'PUT',
        body: request
      }),
      invalidatesTags: ['User']
    }),

    signup: build.mutation<void, SignupRequest>({
      query: (request) => ({
        url: 'api/signup',
        method: 'POST',
        body: request
      }),
      invalidatesTags: ['User']
    }),

    /*
    Workflows
     */

    createWorkflow: build.mutation<WorkflowBasic, WorkflowCreateRequest>({
      query: (req) => ({
        url: `/api/workflows`,
        method: 'POST',
        body: req
      }),
      invalidatesTags: []
    }),
    runWorkflow: build.mutation<void, string>({
      query: (workflowInstanceId) => ({
        url: `/api/workflows/${workflowInstanceId}/execute`,
        method: 'PUT'
      }),
      invalidatesTags: []
    }),

    getWorkflowDefinitionById: build.query<WorkflowDefinition, string>({
      query: (workflowId) => `/api/workflow-definitions/${workflowId}`,
      providesTags: (result, error?) => (error ? [] : [{ type: 'WorkflowDefinition' as const, id: result.workflowId }])
    }),

    getWorkflowInstanceById: build.query<WorkflowInstance, string>({
      query: (workflowInstanceId) => `/api/workflows/${workflowInstanceId}`,
      providesTags: (result, error?) =>
        error ? [] : [{ type: 'WorkflowInstance' as const, id: result.workflowInstanceId }]
    }),

    resetWorkflowInstanceById: build.mutation<void, string>({
      query: (workflowInstanceId) => ({
        url: `/api/workflows/${workflowInstanceId}/reset`,
        method: 'DELETE'
      })
    }),

    deleteWorkflowInstanceById: build.mutation<void, string>({
      query: (workflowInstanceId) => ({
        url: `/api/workflows/${workflowInstanceId}`,
        method: 'DELETE'
      })
    }),

    getTaskInstances: build.query<TaskInstance[], TaskInstanceIdentity>({
      query: (task) => `/api/workflows/${task.workflowInstanceId}/tasks/${task.taskId}/results`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ taskInstanceId }) => ({
                type: 'TaskInstance' as const,
                id: taskInstanceId
              }))
            ]
          : []
    }),

    getWorkflows: build.query<WorkflowBasic[], void>({
      query: () => '/api/workflows',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ workflowId }) => ({
                type: 'WorkflowBasic' as const,
                id: workflowId
              })),
              { type: 'WorkflowBasic', id: 'LIST' }
            ]
          : [{ type: 'WorkflowBasic', id: 'LIST' }]
    }),

    getTaskById: build.query<Task, string>({
      query: (taskId) => `/api/tasks/${taskId}`,
      providesTags: (task) => [{ type: 'Task', id: task.base.taskId }]
    }),

    /*
    Documents
     */

    getDocuments: build.query<DocumentDetails[], void>({
      query: () => '/api/documents',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Document' as const,
                id
              })),
              { type: 'Document', id: 'LIST' }
            ]
          : [{ type: 'Document', id: 'LIST' }]
    }),
    downloadDocument: build.query<unknown, string>({
      query(documentId) {
        return {
          url: `/documents/download/${documentId}`,
          method: 'GET',
          responseHandler: async (response) =>
            window.location.assign(window.URL.createObjectURL(await response.blob())),
          cache: 'no-cache'
        };
      }
    }),
    queryDocuments: build.query<DocumentDetails[], DocumentQuery>({
      query: (documentQuery) => ({
        url: '/api/documents/query',
        method: 'POST',
        body: documentQuery
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Document' as const,
                id
              })),
              { type: 'Document', id: 'LIST' }
            ]
          : [{ type: 'Document', id: 'LIST' }]
    }),

    getDocumentById: build.query<DocumentFull, string>({
      query: (documentId) => `/api/documents/${documentId}`,
      providesTags: (doc, error, documentId) => [{ type: 'Document', id: documentId }]
    }),

    getDocumentSetById: build.query<DocumentSet, string>({
      query: (documentSetId) => `/api/documents/sets/${documentSetId}`,
      providesTags: (doc, error, id) => [{ type: 'DocumentSet', id: id }]
    }),

    getExtractionsByDocumentId: build.query<TextExtraction[], string>({
      query: (documentId) => `/api/documents/${documentId}/extractions`,
      providesTags: (doc, error, documentId) => [{ type: 'DocumentAnnotations', id: documentId }]
    }),

    getDocumentPageAnnotations: build.query<TextAnnotation[], DocumentPageRequest>({
      query: ({ documentId, page, types }) =>
        `/api/documents/${documentId}/page/${page}/annotations?types=${types.toString()}`,
      providesTags: (doc, error, { documentId, page }) => [
        { type: 'DocumentPageAnnotations', id: documentId + '-' + page }
      ]
    }),

    getDocumentPageLines: build.query<DocumentLine[], DocumentPageRequest>({
      query: ({ documentId, page }) => `/api/documents/${documentId}/page/${page}/lines`,
      providesTags: (doc, error, { documentId, page }) => [
        { type: 'DocumentPageStructure', id: documentId + '-' + page }
      ]
    }),
    putUploadFiles: build.mutation<ProcessDetails, FormData>({
      query: (formData) => {
        let url = '/api/documents/upload';

        if (formData.has('processId')) {
          url += `?processId=${formData.get('processId')}`;
        }

        if (formData.has('referenceId')) {
          const referenceId = formData.get('referenceId');
          const referenceType = formData.get('referenceType') || '';
          url += `${url.includes('?') ? '&' : '?'}referenceId=${referenceId}&referenceType=${referenceType}`;
          const documentSetId = formData.get('documentSetId');
          if (documentSetId != null && documentSetId != 'undefined') {
            url += `&documentSetId=${documentSetId}`;
          }
        }

        return {
          url,
          method: 'PUT',
          body: formData
        };
      },
      invalidatesTags: [
        { type: 'Processes', id: 'LIST' },
        { type: 'Document', id: 'LIST' }
      ]
    }),
    postUploadFiles: build.mutation<ChatUploadResponse, CreateUploadRequest>({
      query: (req) => ({
        url: '/api/documents/upload',
        method: 'POST',
        body: req
      }),
      invalidatesTags: ['Processes', 'Document']
    }),
    getForm: build.query<Form, string>({
      query: (formId) => ({
        url: `/api/forms/${formId}`,
        providesTags: []
      })
    }),
    putForm: build.mutation<void, Form>({
      query: (form) => ({
        url: `/api/forms/${form.id}`,
        method: 'PUT',
        body: form
      }),
      invalidatesTags: []
    }),
    getChatItems: build.query<ChatItem[], string>({
      query: (chatId) => ({
        url: `/api/chats/${chatId}/items`
      }),
      providesTags: (result, error, chatId) => [{ type: 'Chat', id: chatId }]
    }),
    generateUserChat: build.mutation<ChatCreatedResponse, void>({
      query: () => ({
        url: `/api/chats/user/generate`,
        method: 'POST'
      }),
      invalidatesTags: ['Chat']
    }),
    postChatMessage: build.mutation<ChatMessageCreatedResponse, ChatCreateMessageRequest>({
      query: (req) => ({
        url: `/api/chats/${req.chatId}/messages`,
        method: 'POST',
        body: req
      }),
      invalidatesTags: []
    }),
    putChatMessage: build.mutation<ChatMessageUpdatedResponse, ChatUpdateMessageRequest>({
      query: (req) => ({
        url: `/api/chats/${req.chatId}/messages/${req.chatMessageId}`,
        method: 'PUT',
        body: req
      }),
      invalidatesTags: []
    }),
    deleteChatMessage: build.mutation<void, ChatDeleteMessageRequest>({
      query: (req) => ({
        url: `/api/chats/${req.chatId}/messages/${req.chatMessageId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Chat']
    }),
    postChatText: build.mutation<ChatMessage, ChatCreateTextRequest>({
      query: (req) => ({
        url: `/api/chats/${req.chatId}/texts`,
        method: 'POST',
        body: req
      }),
      invalidatesTags: []
    }),
    getCup: build.query<Cup, string>({
      query: (cupId) => ({
        url: `/api/cups/${cupId}`
      }),
      providesTags: (cup) => [{ type: 'Cup', id: cup.id }]
    }),
    getCups: build.query<Cup[], void>({
      query: () => ({
        url: `/api/cups`
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Cup' as const,
                id
              })),
              { type: 'Cup', id: 'LIST' }
            ]
          : [{ type: 'Cup', id: 'LIST' }]
    }),
    getCupTabularExampleData: build.query<CupTabularExampleData, CupGetTabularExampleData>({
      query: (req) => ({
        url: `/api/cups/${req.cupId}/tabular-data/${req.id}/example-data`
      }),
      providesTags: (result) =>
        result
          ? [
              {
                type: 'CupTabularExampleData' as const,
                id: result.id
              }
            ]
          : [{ type: 'CupTabularExampleData', id: 'LIST' }]
    }),
    postCup: build.mutation<Cup, CupCreate>({
      query: (req) => ({
        url: `/api/cups`,
        method: 'POST',
        body: req
      }),
      invalidatesTags: ['Cup']
    }),
    deleteCupById: build.mutation<void, string>({
      query: (cupId) => ({ url: `/api/cups/${cupId}`, method: 'DELETE' }),
      invalidatesTags: ['Cup']
    }),
    postCupComplete: build.mutation<Cup, CupCreateComplete>({
      query: (req) => ({
        url: `/api/cups/complete`,
        method: 'POST',
        body: req
      }),
      invalidatesTags: ['Cup']
    }),
    postCupExtraction: build.mutation<Cup, CupCreateExtraction>({
      query: (req) => ({
        url: `/api/cups/${req.cupId}/extractions`,
        method: 'POST',
        body: req
      }),
      invalidatesTags: ['Cup']
    }),
    putCupExtraction: build.mutation<Cup, CupCreateExtraction>({
      query: (req) => ({
        url: `/api/cups/${req.cupId}/extractions`,
        method: 'PUT',
        body: req
      }),
      invalidatesTags: ['Cup']
    }),
    putCupLabels: build.mutation<Cup, CupSaveLabels>({
      query: (req) => ({
        url: `/api/cups/${req.cupId}/labels`,
        method: 'PUT',
        body: req
      }),
      invalidatesTags: ['Cup']
    }),
    postCupTest: build.mutation<void, CupTest>({
      query: (req) => ({
        url: `/api/cups/${req.cupId}/test`,
        method: 'POST',
        body: req
      }),
      invalidatesTags: []
    }),
    putCupFilter: build.mutation<CupFilterConfig, CupSaveFilter>({
      query: (req) => ({
        url: `/api/cups/${req.cupId}/filters`,
        method: 'PUT',
        body: req
      }),
      invalidatesTags: ['Cup']
    }),
    deleteCupFilter: build.mutation<void, CupDeleteFilter>({
      query: (req) => ({
        url: `/api/cups/${req.cupId}/filters/${req.filterId}`,
        method: 'DELETE',
        body: req
      }),
      invalidatesTags: ['Cup']
    }),
    putCupTabularDataColumn: build.mutation<void, CupUpdateTabularDataColumn>({
      query: (req) => ({
        url: `/api/cups/${req.cupId}/tabular-data/${req.tabularDataId}/${req.index}`,
        method: 'PUT',
        body: req
      }),
      invalidatesTags: ['Cup']
    }),
    postMirror: build.mutation<Mirror, MirrorCreate>({
      query: (req) => ({
        url: `/api/mirrors`,
        method: 'POST',
        body: req
      }),
      invalidatesTags: ['Mirror']
    }),
    postMirrorCreateCupProvider: build.mutation<Mirror, MirrorCupProviderCreate>({
      query: (req) => ({
        url: `/api/mirrors/${req.mirrorId}/providers/cup`,
        method: 'POST',
        body: req
      }),
      invalidatesTags: ['Mirror']
    }),
    postMirrorCreateUserProvider: build.mutation<Mirror, MirrorUserProviderCreate>({
      query: (req) => ({
        url: `/api/mirrors/${req.mirrorId}/providers/user`,
        method: 'POST',
        body: req
      }),
      invalidatesTags: ['Mirror']
    }),
    putMirrorName: build.mutation<void, MirrorNameUpdate>({
      query: (req) => ({
        url: `/api/mirrors/${req.mirrorId}/name`,
        method: 'PUT',
        body: req
      })
    }),
    putMirrorLabelProvider: build.mutation<MirrorLabelProvider, MirrorLabelProviderUpdate>({
      query: (req) => ({
        url: `/api/mirrors/${req.mirrorId}/providers/${req.providerId}`,
        method: 'PUT',
        body: req
      })
    }),
    getMirrors: build.query<MirrorBasic[], void>({
      query: () => ({
        url: '/api/mirrors',
        method: 'GET'
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'MirrorBasic' as const,
                id
              })),
              { type: 'MirrorBasic', id: 'LIST' }
            ]
          : [{ type: 'MirrorBasic', id: 'LIST' }]
    }),
    getMirrorById: build.query<Mirror, string>({
      query: (mirrorId) => `/api/mirrors/${mirrorId}`,
      providesTags: (result) => [{ type: 'Mirror' as const, id: result.id }]
    }),
    getMirrorStateById: build.query<MirrorState, string>({
      query: (mirrorId) => `/api/mirrors/${mirrorId}/state`,
      providesTags: (result, error, arg) => [{ type: 'MirrorState' as const, id: arg }]
    }),
    deleteMirrorById: build.mutation<MirrorBasic, string>({
      query: (mirrorId) => ({ url: `/api/mirrors/${mirrorId}`, method: 'DELETE' }),
      invalidatesTags: (result) => [
        { type: 'Mirror' as const, id: result.id },
        { type: 'MirrorBasic', id: 'LIST' }
      ]
    }),
    createTask: build.mutation<void, TaskCreateRequest>({
      query: (req) => ({
        url: `/api/workflow-definitions/${req.workflowId}/tasks`,
        method: 'POST',
        body: req
      })
    }),
    putTaskName: build.mutation<void, TaskNameUpdateRequest>({
      query: (req) => ({
        url: `/api/workflow-definitions/${req.workflowId}/tasks/${req.taskId}/name`,
        method: 'PUT',
        body: req
      })
    }),
    createTaskInputParameter: build.mutation<void, TaskInputParameterCreate>({
      query: (req) => ({
        url: `/api/workflow-definitions/${req.workflowId}/tasks/${req.taskId}/inputs`,
        method: 'POST',
        body: req
      })
    }),
    addTaskInputParameters: build.mutation<void, TaskInputParametersAdd>({
      query: (req) => ({
        url: `/api/workflow-definitions/${req.workflowId}/tasks/${req.taskId}/inputs/add`,
        method: 'POST',
        body: req
      })
    }),
    deleteTaskInputParameter: build.mutation<void, TaskParameterRequest>({
      query: (req) => ({
        url: `/api/workflow-definitions/${req.workflowId}/tasks/${req.taskId}/inputs/${req.parameterId}`,
        method: 'DELETE',
        body: req
      })
    }),
    createTaskOutputParameter: build.mutation<OutputParameterDefinition, TaskOutputParameterCreate>({
      query: (req) => ({
        url: `/api/workflow-definitions/${req.workflowId}/tasks/${req.taskId}/outputs`,
        method: 'POST',
        body: req
      })
    }),
    deleteTaskOutputParameter: build.mutation<void, TaskParameterRequest>({
      query: (req) => ({
        url: `/api/workflow-definitions/${req.workflowId}/tasks/${req.taskId}/outputs/${req.parameterId}`,
        method: 'DELETE',
        body: req
      })
    }),
    deleteTask: build.mutation<void, TaskRequest>({
      query: (req) => ({
        url: `/api/workflow-definitions/${req.workflowId}/tasks/${req.taskId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['WorkflowDefinition']
    }),
    getJoinTask: build.query<TaskJoinResponse, TaskRequest>({
      query: (req) => `/api/workflow-definitions/${req.workflowId}/tasks/${req.taskId}/join`,
      providesTags: (result, error?) => (error ? [] : [{ type: 'TaskJoin' as const, id: result.taskId }])
    }),
    updateJoinTask: build.mutation<TaskJoinResponse, TaskJoinUpdate>({
      query: (req) => ({
        url: `/api/workflow-definitions/${req.workflowId}/tasks/${req.taskId}/join`,
        method: 'PUT',
        body: {
          taskId: req.taskId,
          keys: req.keys
        }
      }),
      invalidatesTags: ['TaskJoin']
    }),
    getLogicTaskExpressions: build.query<TaskLogicExpressionResponse, TaskRequest>({
      query: (req) => `/api/workflow-definitions/${req.workflowId}/tasks/${req.taskId}/logic/expressions`,
      providesTags: ({ taskId }) => [{ type: 'TaskLogicExpression', id: taskId }]
    }),
    saveLogicTaskExpression: build.mutation<void, TaskLogicExpressionUpdate>({
      query: (req) => ({
        url: `/api/workflow-definitions/${req.workflowId}/tasks/${req.taskId}/logic/expressions/${req.mappedTo}`,
        method: 'PUT',
        body: req.update
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'TaskLogicExpression' as const, id: arg.taskId }]
    }),
    deleteLogicTaskExpression: build.mutation<void, TaskLogicExpressionRequest>({
      query: (req) => ({
        url: `/api/workflow-definitions/${req.workflowId}/tasks/${req.taskId}/logic/expressions/${req.mappedTo}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'TaskLogicExpression' as const, id: arg.taskId }]
    }),
    getExportTaskConfig: build.query<TaskExportConfigResponse, TaskRequest>({
      query: (req) => `/api/workflow-definitions/${req.workflowId}/tasks/${req.taskId}/export`,
      providesTags: ({ taskId }) => [{ type: 'TaskExportConfig', id: taskId }]
    }),
    putExportTaskConfig: build.mutation<void, TaskExportConfigUpdate>({
      query: (req) => ({
        url: `/api/workflow-definitions/${req.workflowId}/tasks/${req.taskId}/export`,
        method: 'PUT',
        body: req
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'TaskExportConfig' as const, id: arg.taskId }]
    }),
    addExportTaskColumns: build.mutation<void, TaskExportColumnsAdd>({
      query: (req) => ({
        url: `/api/workflow-definitions/${req.workflowId}/tasks/${req.taskId}/export/columns`,
        method: 'PUT',
        body: req
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'TaskExportConfig' as const, id: arg.taskId }]
    }),
    createExportTaskColumn: build.mutation<void, TaskExportColumnCreate>({
      query: (req) => ({
        url: `/api/workflow-definitions/${req.workflowId}/tasks/${req.taskId}/export/columns`,
        method: 'POST',
        body: req
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'TaskExportConfig' as const, id: arg.taskId }]
    }),
    getAggregateTaskConfig: build.query<TaskAggregateConfig, TaskRequest>({
      query: (req) => `/api/workflow-definitions/${req.workflowId}/tasks/${req.taskId}/aggregate`,
      providesTags: ({ taskId }) => [{ type: 'TaskAggregateConfig', id: taskId }]
    }),
    putAggregateTaskConfig: build.mutation<void, TaskAggregateConfigUpdate>({
      query: (req) => ({
        url: `/api/workflow-definitions/${req.workflowId}/tasks/${req.taskId}/aggregate`,
        method: 'PUT',
        body: req.config
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'TaskAggregateConfig' as const, id: arg.taskId },
        { type: 'WorkflowDefinition', id: arg.workflowId }
      ]
    }),
    getLabelProviderReference: build.query<LabelProviderReference, TaskRequest>({
      query: (req) => `/api/workflow-definitions/${req.workflowId}/tasks/${req.taskId}/label-provider`,
      providesTags: ({ providerId }) => [{ type: 'LabelProviderReference', id: providerId }]
    }),
    getLabels: build.query<Label_[], LabelGet>({
      query: (params: LabelGet) => {
        const { category, search, language } = params;
        let url = '/api/labels?';

        if (category) {
          url += `category=${encodeURIComponent(category)}&`;
        }
        if (search) {
          url += `search=${encodeURIComponent(search)}&`;
        }
        if (language) {
          url += `language=${encodeURIComponent(language)}&`;
        }

        url = url.slice(0, -1);

        return {
          url,
          method: 'GET'
        };
      },
      providesTags: ['Label']
    }),
    postLabel: build.mutation<Label, LabelCreate>({
      query: (req) => ({
        url: `/api/labels`,
        method: 'POST',
        body: req
      }),
      invalidatesTags: ['Label']
    }),
    postDevCommand: build.mutation<void, void>({
      query: () => ({
        url: `/api/dev?command=createMirror`,
        method: 'POST'
      }),
      invalidatesTags: []
    }),
    getDemoState: build.query<DemoState, void>({
      query: () => `/api/demo/state`,
      providesTags: ['DemoState']
    }),
    putDemoState: build.mutation<void, DemoStateUpdate>({
      query: (req) => ({
        url: `/api/demo/state`,
        method: 'PUT',
        body: req
      }),
      invalidatesTags: ['DemoState']
    }),

    adminUsersToActivate: build.query<User[], void>({
      query: () => `/api/admin/users/new`,
      providesTags: ['UsersToActivate']
    }),
    adminActivateUser: build.mutation<void, string>({
      query: (userId) => ({
        url: `/api/admin/users/${userId}/activate`,
        method: 'PUT'
      }),
      invalidatesTags: ['UsersToActivate']
    })
  })
});

export const {
  //
  // Admin
  //
  useAdminUsersToActivateQuery,
  useAdminActivateUserMutation,
  //
  // Extraction
  //
  useGetExtractionsByDocumentIdQuery,
  useLazyGetExtractionsByDocumentIdQuery,
  useGetContractingPartyQuery,
  //
  // Documents
  //
  useGetDocumentSetByIdQuery,
  useGetDocumentsQuery,
  useQueryDocumentsQuery,
  useGetDocumentByIdQuery,
  useGetDocumentPageAnnotationsQuery,
  useGetDocumentPageLinesQuery,
  useGetConfigQuery,
  useSignupMutation,
  useMeQuery,
  useLogoutUserMutation,
  useUpdateUserMutation,
  usePostUploadFilesMutation,
  usePutUploadFilesMutation,
  useGetFormQuery,
  usePutFormMutation,
  useLazyDownloadDocumentQuery,
  //
  // Chat
  //
  useGetChatItemsQuery,
  useGenerateUserChatMutation,
  usePostChatMessageMutation,
  usePutChatMessageMutation,
  useDeleteChatMessageMutation,
  //
  // Workflow
  //
  useCreateWorkflowMutation,
  useGetWorkflowDefinitionByIdQuery,
  useGetWorkflowInstanceByIdQuery,
  useGetWorkflowsQuery,
  useRunWorkflowMutation,
  useGetTaskInstancesQuery,
  useResetWorkflowInstanceByIdMutation,
  useDeleteWorkflowInstanceByIdMutation,
  //
  // Cups
  //
  useGetCupQuery,
  useGetCupsQuery,
  useGetCupTabularExampleDataQuery,
  usePostCupMutation,
  useDeleteCupByIdMutation,
  usePostCupExtractionMutation,
  usePutCupTabularDataColumnMutation,
  usePutCupExtractionMutation,
  usePutCupLabelsMutation,
  usePutCupFilterMutation,
  useDeleteCupFilterMutation,
  //
  // Mirror
  //
  usePostMirrorMutation,
  usePostMirrorCreateCupProviderMutation,
  usePostMirrorCreateUserProviderMutation,
  usePutMirrorLabelProviderMutation,
  usePutMirrorNameMutation,
  useGetMirrorsQuery,
  useGetMirrorByIdQuery,
  useGetMirrorStateByIdQuery,
  useDeleteMirrorByIdMutation,
  //
  // Task
  //
  usePutTaskNameMutation,
  useCreateTaskInputParameterMutation,
  useAddTaskInputParametersMutation,
  useDeleteTaskInputParameterMutation,
  useCreateTaskOutputParameterMutation,
  useDeleteTaskOutputParameterMutation,
  useUpdateJoinTaskMutation,
  useGetJoinTaskQuery,
  useGetLogicTaskExpressionsQuery,
  useSaveLogicTaskExpressionMutation,
  useDeleteLogicTaskExpressionMutation,
  useGetLabelProviderReferenceQuery,
  useGetAggregateTaskConfigQuery,
  usePutAggregateTaskConfigMutation,
  useGetExportTaskConfigQuery,
  usePutExportTaskConfigMutation,
  useAddExportTaskColumnsMutation,
  useCreateExportTaskColumnMutation,
  useCreateTaskMutation,
  useDeleteTaskMutation,
  //
  // Label
  //
  useGetLabelsQuery,
  usePostLabelMutation,
  //
  // Dev
  //
  usePostDevCommandMutation,
  useGetDemoStateQuery,
  usePutDemoStateMutation
} = api;
