import { Box } from '@mui/material';
import React from 'react';
import { DocumentPage } from '../../types/document';
import { useGetDocumentPageAnnotationsQuery, useGetDocumentPageLinesQuery } from '../../state/api';
import { DocumentPageRequest, TextAnnotationType } from '../../types/annotation';
import DocumentOverlay from './DocumentOverlay';

interface DocumentPageProps {
  documentId: string;
  page: DocumentPage;
  showAnnotations: TextAnnotationType[];
}

const DocumentPageSection = ({ documentId, page, showAnnotations }: DocumentPageProps) => {
  const pageRequest: DocumentPageRequest = {
    documentId,
    page: page.page,
    types: showAnnotations
  };

  const { data: lines = [] } = useGetDocumentPageLinesQuery(pageRequest);

  const { data: annotations = [] } = useGetDocumentPageAnnotationsQuery(pageRequest, {
    skip: showAnnotations.length === 0
  });

  const imageUri = `/api/documents/${documentId}/page/${page.page}/image`;
  const viewBox = `0 0 ${page.image.width} ${page.image.height}`;

  return (
    <Box
      sx={{
        display: 'grid',
        maxWidth: '48rem'
      }}
    >
      <Box
        component="svg"
        sx={{
          fill: 'none',
          display: 'block',
          maxWidth: '100%',
          boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.2)',
          backgroundColor: '#fff',
          gridArea: '1 / 1'
        }}
        viewBox={viewBox}
      >
        <Box component="image" x={0} y={0} width="100%" href={imageUri} sx={{ zIndex: '50' }} />
      </Box>

      <DocumentOverlay width={page.image.width} height={page.image.height} lines={lines} annotations={annotations} />
    </Box>
  );
};

export default DocumentPageSection;
