import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';

interface CreateButtonProps {
  title: string;
  onClick: () => void;
  disabled?: boolean;
}

const CreateButton = ({ title, onClick, disabled = false }: CreateButtonProps) => {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
      onClick={() => onClick()}
      size="medium"
      disabled={disabled}
    >
      {title}
    </Button>
  );
};

export default CreateButton;
