import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import { api, useLogoutUserMutation } from '../../state/api';
import { logout } from '../../state/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Activation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logoutUser] = useLogoutUserMutation();

  const handleLogout = () => {
    logoutUser()
      .unwrap()
      .then(() => {
        dispatch(api.util.resetApiState());
        dispatch(logout());
        navigate('/mirrors', { replace: true });
      });
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ pt: 10 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
          padding: '2rem',
          alignItems: 'center'
        }}
      >
        <Typography variant="h4" component="h3">
          Welcome
        </Typography>
        <Typography variant="body1">Your account needs to be activated.</Typography>
        <Button variant="contained" fullWidth onClick={handleLogout}>
          Logout
        </Button>
      </Box>
    </Container>
  );
};

export default Activation;
