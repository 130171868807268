import * as React from 'react';
import { createContext, ReactNode, useMemo, useState } from 'react';
import {
  TaskDefinition,
  TaskParameter,
  TaskParameterDefinition,
  TaskType,
  WorkflowDefinition,
  WorkflowId
} from '../../types/task';
import { LabelAlias, LabelParameters } from '../../types/label';
import { useGetWorkflowDefinitionByIdQuery } from '../../state/api';
import { getAvailableOutputs, getAvailableOutputsByTask } from '../../types/utils/workflows';

export const nodeId = (node: LabelAlias): string => {
  return node.labelId + node.name;
};

interface MirrorContextType {
  workflow: WorkflowDefinition;
  availableLabels: LabelParameters[];
  availableOutputs: TaskParameter[];
  selectedLabels: LabelAlias[];
  selectLabel: (label: LabelAlias) => void;
  isLabelSelected: (label: LabelAlias) => boolean;
  clearSelection: () => void;
  getAvailableOutputsForTask: (task: TaskDefinition) => TaskParameterDefinition[];
}

const MirrorContext = createContext<MirrorContextType>({
  workflow: {} as WorkflowDefinition,
  availableLabels: [],
  selectedLabels: [],
  availableOutputs: [],
  isLabelSelected: () => undefined,
  selectLabel: () => undefined,
  clearSelection: () => undefined,
  getAvailableOutputsForTask: () => []
});

interface MirrorContextProviderProps {
  workflowId: WorkflowId;
  children: ReactNode;
}

function MirrorContextProvider({ workflowId, children }: MirrorContextProviderProps) {
  const [selectedLabels, setSelectedLabels] = useState<LabelAlias[]>([]);

  const { data: workflow } = useGetWorkflowDefinitionByIdQuery(workflowId, {
    skip: workflowId.length === 0
  });

  const availableLabels: LabelParameters[] = useMemo(() => {
    if (workflow && workflow.tasks) {
      const mapped = workflow.tasks
        .filter((node) => node.type !== TaskType.GROUP && node.type !== TaskType.START && node.type !== TaskType.END)
        .filter((node) => node.input.length > 0)
        .flatMap((node) => node.input)
        .map((node) => {
          const mapped: LabelParameters = {
            labelId: node.labelId,
            name: node.name,
            type: node.type,
            description: node.description,
            parameterIds: [node.parameterId],
            fulfilled: node.fulfilled
          };
          return mapped;
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      return Array.from(new Set(mapped.map(nodeId))).map((id) => {
        return mapped
          .filter((node) => nodeId(node) === id)
          .reduce((prev, curr) => {
            return {
              ...prev,
              parameterIds: [...prev.parameterIds, ...curr.parameterIds]
            };
          });
      });
    }
    return [];
  }, [workflow]);

  const availableOutputs: TaskParameter[] = useMemo(() => {
    return getAvailableOutputs(workflow);
  }, [workflow]);

  const getAvailableOutputsForTask = (task: TaskDefinition): TaskParameter[] => {
    return getAvailableOutputsByTask(workflow.tasks, (t) => t.taskId !== task.taskId);
  };

  const clearSelection = (): void => {
    setSelectedLabels([]);
  };

  const isLabelSelected = (param: LabelAlias): boolean => {
    return selectedLabels.findIndex((node) => nodeId(node) === nodeId(param)) >= 0;
  };

  const handleLabelSelected = (param: LabelAlias) => {
    if (isLabelSelected(param)) {
      setSelectedLabels((prev) => prev.filter((p) => nodeId(p) !== nodeId(param)));
    } else {
      setSelectedLabels((prev) => [...prev, param]);
    }
  };

  const provides: MirrorContextType = useMemo(
    () => ({
      workflow: workflow,
      availableLabels: availableLabels,
      availableOutputs: availableOutputs,
      selectedLabels: selectedLabels,
      isLabelSelected: isLabelSelected,
      selectLabel: handleLabelSelected,
      clearSelection: clearSelection,
      getAvailableOutputsForTask: getAvailableOutputsForTask
    }),
    [availableLabels, selectedLabels]
  );

  return <MirrorContext.Provider value={provides}>{children}</MirrorContext.Provider>;
}

function useMirrorContext() {
  const context = React.useContext(MirrorContext);
  if (context === undefined) {
    throw new Error('useMirrorContext must be used within a MirrorContextProvider');
  }
  return context;
}

export { MirrorContextProvider, useMirrorContext };
