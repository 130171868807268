import React, { useMemo, useState } from 'react';
import { Mirror } from '../../../types/mirror';
import {
  ExportFormatColumn,
  TaskDefinition,
  TaskExportConfigUpdate,
  TaskParameterDefinition
} from '../../../types/task';
import {
  useAddExportTaskColumnsMutation,
  useCreateExportTaskColumnMutation,
  useGetExportTaskConfigQuery,
  usePutExportTaskConfigMutation
} from '../../../state/api';
import { MirrorTaskEditorPanel } from './MirrorTaskEditorPanel';
import { MirrorExportTaskColumnEditor } from './MirrorExportTaskColumnEditor';
import { SectionPanel } from '../../../components/SectionPanel';
import { Box, Button, ButtonGroup } from '@mui/material';
import InputParameterAddForm from './InputParameterAddForm';
import { useMirrorContext } from '../MirrorContextProvider';

interface MirrorExportTaskEditorProps {
  mirror: Mirror;
  task: TaskDefinition;
  onClose: () => void;
}

export const MirrorExportTaskEditor = ({ mirror, task, onClose }: MirrorExportTaskEditorProps) => {
  const [addExportColumns] = useAddExportTaskColumnsMutation();
  const [createExportColumn] = useCreateExportTaskColumnMutation();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { availableOutputs } = useMirrorContext();

  const [putTaskExportConfig] = usePutExportTaskConfigMutation();

  const [selectedColumns, setSelectedColumns] = useState<ExportFormatColumn[]>([]);
  const { data: exportConfig, isLoading } = useGetExportTaskConfigQuery({
    workflowId: mirror.workflowId,
    taskId: task.taskId
  });

  const columns: ExportFormatColumn[] = useMemo(() => {
    if (exportConfig) {
      return exportConfig.format.columns;
    }
    return [];
  }, [exportConfig]);

  const possibleOutputs = useMemo(() => {
    const mapped = columns.flatMap((c) => c.mappedBy.mappedBy);
    return availableOutputs.filter((o) => !mapped.includes(o.parameterId));
  }, [availableOutputs, columns]);

  const unmappedColumns = useMemo(() => columns.filter((c) => c.mappedBy.mappedBy === undefined), [columns]);

  const clearSelection = (): void => {
    setSelectedColumns([]);
  };

  const selectAll = (): void => {
    setSelectedColumns(columns);
  };

  const selectUnmapped = (): void => {
    setSelectedColumns(unmappedColumns);
  };

  const deleteSelected = (): void => {
    const selectedIds = selectedColumns.map((c) => c.index);
    const updated = columns.filter((c) => !selectedIds.includes(c.index));

    updateConfig(updated).then(() => {
      clearSelection();
    });
  };

  const isColumnSelected = (column: ExportFormatColumn): boolean => {
    return selectedColumns.findIndex((c) => c.index === column.index) >= 0;
  };

  const handleColumnDelete = (update: ExportFormatColumn) => {
    const updatedColumns = columns.filter((column) => column.index !== update.index);
    updateConfig(updatedColumns);
  };

  const handleSelected = (column: ExportFormatColumn) => {
    if (isColumnSelected(column)) {
      setSelectedColumns((prev) => prev.filter((p) => p.index !== column.index));
    } else {
      setSelectedColumns((prev) => [...prev, column]);
    }
  };

  const handleAddColumns = async (parameters: TaskParameterDefinition[]) => {
    try {
      const addRequest = {
        workflowId: mirror.workflowId,
        taskId: task.taskId,
        columns: parameters.map((p) => {
          return {
            labelId: p.labelId,
            mappedBy: p.parameterId
          };
        })
      };

      await addExportColumns(addRequest).unwrap();
    } catch (error) {
      if (error.status) {
        setErrorMessage('Adding input column failed.');
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
      }
    }
  };

  const handleCreateParameter = (name: string) => {
    createExportColumn({
      workflowId: mirror.workflowId,
      taskId: task.taskId,
      name: name
    });
  };

  const onDragEnd = (updated: ExportFormatColumn[]) => {
    updateConfig(updated);
  };

  const updateConfig = async (updated: ExportFormatColumn[]) => {
    try {
      const updateRequest: TaskExportConfigUpdate = {
        workflowId: mirror.workflowId,
        taskId: task.taskId,
        format: {
          ...exportConfig.format,
          columns: updated
        },
        filenamePattern: exportConfig.filenamePattern
      };

      await putTaskExportConfig(updateRequest).unwrap();
    } catch (error) {
      if (error.status) {
        setErrorMessage('Failed to update export configuration.');
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
      }
    }
  };

  return (
    <MirrorTaskEditorPanel
      mirror={mirror}
      task={task}
      onClose={onClose}
      help="Add and rearange labels to be exported"
      loading={isLoading}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: 'auto 1fr 1fr'
        }}
      >
        <SectionPanel title="Labels to be exported">
          <MirrorExportTaskColumnEditor
            workflowId={mirror.workflowId}
            taskId={task.taskId}
            exportConfig={exportConfig}
            columns={columns}
            isColumnSelected={isColumnSelected}
            onSaveConfig={onDragEnd}
            onColumnClick={handleSelected}
            onColumnDelete={handleColumnDelete}
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1
            }}
          >
            <ButtonGroup size="small">
              <Button variant="outlined" color="info" onClick={() => selectAll()}>
                Select All
              </Button>
              <Button
                variant="outlined"
                color="info"
                disabled={selectedColumns.length === 0}
                onClick={() => clearSelection()}
              >
                Deselect All
              </Button>
              <Button variant="outlined" disabled={unmappedColumns.length === 0} onClick={() => selectUnmapped()}>
                Select Unmapped
              </Button>
            </ButtonGroup>

            <Button
              variant="outlined"
              color="warning"
              disabled={selectedColumns.length === 0}
              onClick={() => deleteSelected()}
              size="small"
            >
              Delete {selectedColumns.length > 0 && selectedColumns.length}
            </Button>
          </Box>
        </SectionPanel>

        <SectionPanel title="Add Labels" variant="subtitle2">
          <InputParameterAddForm
            availableOutputs={possibleOutputs}
            onAddParameters={handleAddColumns}
            onCreateParameter={handleCreateParameter}
          />
        </SectionPanel>
      </Box>
      {errorMessage}
    </MirrorTaskEditorPanel>
  );
};
