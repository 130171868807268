import React from 'react';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MapIcon from '@mui/icons-material/Map';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { DocumentDetails } from '../../types/document';
import { isDateAnnotation, isStringAnnotation, StandardLabel, TextExtraction } from '../../types/annotation';
import SectionTable from '../../components/SectionTable';

interface DocumentListProps {
  isLoading: boolean;
  documents: DocumentDetails[];
  extractions: Map<string, TextExtraction[]>;
}

const DocumentList = ({ documents, extractions, isLoading }: DocumentListProps) => {
  const navigate = useNavigate();

  const hasLocation = (text: DocumentDetails) => {
    return text.parties.some((t) => t.location && t.location.lat > 0);
  };

  const normalisedExtractionValue = (extraction: TextExtraction): string => {
    if (isStringAnnotation(extraction)) {
      return extraction.value.string;
    } else if (isDateAnnotation(extraction)) {
      return extraction.value.date ? extraction.value.date.toString() : '';
    } else {
      return '';
    }
  };

  const extractionValue = (docId: string, label: StandardLabel) => {
    if (extractions) {
      const docExtractions = extractions.get(docId);
      if (docExtractions) {
        const extraction = docExtractions.find((extraction) => extraction.labelName === label);
        if (extraction) {
          return normalisedExtractionValue(extraction);
        }
      } else {
        return '';
      }
    }
  };

  const normalizeSupplier = (name: string): string => {
    if (name) {
      let result = name.replace(/\s+/g, ' ');
      result = result.replace(/[.,]/g, '');
      result = result.replace(/[()]/g, '');
      return result.toUpperCase();
    } else {
      return '';
    }
  };

  return (
    <SectionTable>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Supplier</TableCell>
          <TableCell>Start Date</TableCell>
          <TableCell>End Date</TableCell>
          <TableCell>Governing Law</TableCell>
          <TableCell>Language</TableCell>
          <TableCell>Pages</TableCell>
          <TableCell>
            <MapIcon />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!isLoading &&
          documents &&
          documents.map((text) => (
            <TableRow key={text.id} hover onClick={() => navigate(`/documents/${text.id}`)}>
              <TableCell>
                {text.name.length > 30 ? `${text.name.slice(0, 26)}...${text.name.slice(-4)}` : text.name}
              </TableCell>
              <TableCell>{normalizeSupplier(extractionValue(text.id, StandardLabel.Supplier))}</TableCell>
              <TableCell>{extractionValue(text.id, StandardLabel.StartDate)}</TableCell>
              <TableCell>{extractionValue(text.id, StandardLabel.TerminationDate)}</TableCell>
              <TableCell>{extractionValue(text.id, StandardLabel.GoverningLaw)}</TableCell>
              <TableCell>{text.language}</TableCell>
              <TableCell>{text.pages}</TableCell>
              <TableCell>
                {hasLocation(text) ? <LocationOnIcon color="primary" /> : <LocationOffIcon color="disabled" />}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </SectionTable>
  );
};

export default DocumentList;
