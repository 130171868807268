import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Autocomplete, Checkbox } from '@mui/material';
import { usePutCupLabelsMutation } from '../../state/api';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Cup } from '../../types/cup';
import { Label } from '../../types/label';

export interface CupStandardLabelFormProps {
  cup: Cup;
  labels: Label[];
  onCompleted: () => void;
  onCancel: () => void;
}

export const CupStandardLabelForm = ({ cup, labels, onCompleted, onCancel }: CupStandardLabelFormProps) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [putCupLabels] = usePutCupLabelsMutation();
  const [selectedOptions, setSelectedOptions] = useState<Label[]>(
    labels.filter((label) => cup.labels && cup.labels.indexOf(label.labelId) != -1)
  );

  const handleSubmit = async () => {
    try {
      await putCupLabels({
        cupId: cup.id,
        labels: selectedOptions.map((label) => label.labelId)
      }).unwrap();

      onCompleted();
    } catch (error) {
      console.error('Failed to save form data', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ minWidth: 500, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h6" component="div">
          Select Standard Labels
        </Typography>
        <Typography variant="body2" component="div"></Typography>
        <Autocomplete
          multiple
          getOptionLabel={(option) => option.description.title}
          options={labels}
          disableCloseOnSelect
          value={selectedOptions}
          onChange={(event, newValue) => {
            console.log(newValue);
            setSelectedOptions(newValue);
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {option.description.title}
            </li>
          )}
          renderInput={(params) => <TextField {...params} label="Label Names" placeholder="Labels" />}
        />
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
          <Button variant="contained" type="submit">
            Save
          </Button>
          <Button variant="outlined" type="button" onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      </Box>
    </form>
  );
};
