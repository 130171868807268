import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { TaskDefinition, TaskLogicExpression } from '../../../../types/task';
import { useMirrorContext } from '../../MirrorContextProvider';
import { ParameterAutoComplete } from '../ParameterAutoComplete';
import { LabelAlias } from '../../../../types/label';

interface LogicExpressionFormProps {
  task: TaskDefinition;
  expression: TaskLogicExpression;
  onChange: (parameter: LabelAlias) => void;
  onCreate: (name: string) => void;
}

export const LogicOutputParameterStep = ({ task, expression, onChange, onCreate }: LogicExpressionFormProps) => {
  const { workflow, availableLabels } = useMirrorContext();

  const availableOutputs: LabelAlias[] = useMemo(() => {
    return [...availableLabels, ...task.output.filter((o) => o.parameterId === expression.mappedTo)];
  }, [workflow, task]);

  const handleLabelChange = (label: LabelAlias) => {
    onChange(label);
  };

  const handleLabelCreate = (name: string) => {
    onCreate(name);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1
      }}
    >
      <Typography variant="body2">
        The logic outputs data into a new label. Chose an existing label, or create a new one.
      </Typography>
      <ParameterAutoComplete
        selected={expression.mappedToName}
        availableLabels={availableOutputs}
        onLabelChange={handleLabelChange}
        onLabelCreate={handleLabelCreate}
        label="Output Label"
      />
    </Box>
  );
};
